import { template as template_3edbe1db9f7e4175a1b55dcad44b1fcc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_3edbe1db9f7e4175a1b55dcad44b1fcc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
