import { template as template_d5eccedc670642ed8523f8e65b46281c } from "@ember/template-compiler";
const FKControlMenuContainer = template_d5eccedc670642ed8523f8e65b46281c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
